export enum ROUTE {
  HOME = '/',
  ABOUT = '/about',
  SUMISURA = '/SuMisura',
  FAQ = '/FAQ',
  PRODUCT = '/product',
  CATALOG = '/catalogo',
  BLOG = '/blog',
  BASKET = '/basket',
  PURCHASE = '/purchase',
  CHECKOUT = '/checkout',
  PRIVACY_POLICY = '/policies/privacy-policy',
}

export enum COLOR {
  VIOLET = '#3A264B',
  PURPLE = '#AB7894',
  PURPLE_DARK = '#9C6F88',
  WHITE = '#F2F2F2',
  BLACKOUT = 'rgba(0, 0, 0, 0.47)',
}

export enum BLOG_NAMES {
  NEWS = 'news',
  ARTICLES = 'articles',
}
export const headerRoutesWithMutation = [ROUTE.HOME];

export const MAIN_CONTAINER_X_PADDING = `
        mobile:px-[15px] old:px-[15px]
        desktop:px-[7vw]
        laptop:px-[7vw]
        tablet:px-[4vw]
`;
